/* ------------ VARIABLES ------------ */

:root {
  --lightText: #fff;
  --darkText: #0d1018;
  --headerColor: #fff;
  --sans:  "Arimo", sans-serif, system-ui, -apple-system, Arial, sans-serif;
  --mainColor: #fe5c19;
  --header-height:8vh;
  --content-height:calc(100vh - var(--header-height))
}

input, textarea {
  border : 1px solid #E1E1E1 !important;
}

input:active, textarea:active {
  border : 1px solid #fe5c19 !important;
}

body {
  font-optical-sizing: auto;
  font-family: var(--sans);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.block-header {
  font-family: "Arimo", serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.header {
  width: 100vw;
  height:8vh;
  padding:10px;
  background-color:white;
}
/*.video-bg {
  overflow: hidden;
  height: 95vh;
}*/

.f-display-2 {
  font-family: var(--sans);
  font-size: 2.625rem;
  line-height: 110%;
  letter-spacing: -.01em;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}


/* ------------ LOADING ------------ */
.loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #fff;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}
.loading .bubble {
  width: 20px;
  height: 20px;
  background: var(--mainColor);
  border-radius: 50%;
  animation: jump 1s ease-in-out infinite;
}
.loading .bubble:nth-child(2) {
  animation-delay: 0.2s;
}
.loading .bubble:nth-child(3) {
  animation-delay: 0.4s;
}

.doneLoadingBG {
  background: #000;
  opacity: 0;
  visibility: hidden;
  animation: fadeOut 1s ease-in-out;
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
}
.doneLoadingLeft {
  width: 50vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  left: 0;
  background: #fff;
  visibility: hidden;
  animation: slideLeft 1s ease-in-out;
}
.doneLoadingRight {
  width: 50vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  right: 0;
  background: #fff;
  visibility: hidden;
  animation: slideRight 1s ease-in-out;
}

/* ------------ ANIMATIONS ------------ */
@keyframes slideIn {
  0% {
    left: 100vw;
  }
  100% {
    left: 50vw;
  }
}
@keyframes slideIn2 {
  0% {
    left: 100vw;
  }
  100% {
    left: 30vw;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 100%;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes slideLeft {
  0% {
    left: 0;
    visibility: visible;
  }
  100% {
    left: -50vw;
    visibility: hidden;
  }
}
@keyframes slideRight {
  0% {
    right: 0;
    visibility: visible;
  }
  100% {
    right: -50vw;
    visibility: hidden;
  }
}
@keyframes loadDown {
  0% {
    z-index: 8;
  }
  100% {
    z-index: 8;
    transform: translateY(calc(100% - 20px));
  }
}

@keyframes jump {
  0% {
    transform: translateY(0);
    opacity: 0.5;
  }
  50% {
    transform: translateY(-30px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 0.5;
  }
}


/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 30px;
  top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fe5c19;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #fe5c19;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #fe5c19;
}

/* Wrapper for item list */
.bm-item-list {
  color: white;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.9);
}

.react-multiple-carousel__arrow--right {
  background-color: rgba(254, 92, 25, 0.8) !important;
}

.react-multiple-carousel__arrow--left {
  background-color: rgba(254, 92, 25, 0.8) !important;
}
